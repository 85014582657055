class Lightbox {

  constructor() {
    this.lightboxEl = document.querySelector('.lightbox')
    this.video = this.lightboxEl.querySelector('video')
    this.listen()
  }

  listen() {
    addEventListener('click', ev => {
      if (ev.target.hasAttribute('data-plays-lightbox')) {
        const src = ev.target.nextElementSibling.dataset.video
        this.video.src = src
        this.lightboxEl.classList.add('show')
        this.video.play()
      }
      if (ev.target.classList.contains('lightbox__outer')) {
        this.lightboxEl.classList.remove('show')
        this.video.src = ""
      }
    })
    addEventListener('keydown', ev => {
      if (ev.key === "Escape") {
        this.lightboxEl.classList.remove('show')
        this.video.src = ""
      }
    })
  }

}

export default Lightbox