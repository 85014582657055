class MobileMenu {
  constructor() {
    this.header = document.querySelector('.header')
    this.menu = document.querySelector('.main-nav--header')
    this.button = document.querySelector('.menu-button')
    this.open = false
    this.previous = null
    this.listen()
  }

  listen() {
    // Toggle mobile navigation menu
    this.button.addEventListener('click', (ev) => {
      this.button.classList.toggle('open')
      this.menu.classList.toggle('open')
      this.open = !this.open
      if (this.open) {
        document.body.classList.add('noscroll')
        document.querySelector('.logo').style.color = "white"
        let openItem = this.menu.querySelector('.main-nav__item.open')
        if (openItem) {
          openItem.querySelector('.main-nav__list--sublist').removeAttribute('style')
          openItem.classList.remove('open')
        }
      } else {
        document.body.classList.remove('noscroll')
        document.querySelector('.logo').removeAttribute('style')
      }
    })
    // Toggle dropdown menus
    addEventListener('click', ev => {
      if (this.previous && this.previous === ev.target && ev.target.classList.contains('open')) {
        this.closeMenus()
        this.previous = null
        return
      }
      if (ev.target.hasAttribute('data-dropdown')) {
        this.closeMenus()
        ev.target.classList.add('open')
        let menuHeight = ev.target.querySelector('ul').scrollHeight
        ev.target.querySelector('.main-nav__list--sublist').style.maxHeight = menuHeight + "px"
        if (window.innerWidth > 1024) {
          this.header.classList.add('fill')
          this.header.style.paddingBottom = `calc(3rem + ${menuHeight}px)`
        }
        this.previous = ev.target
      } else if (ev.target.classList.contains('main-nav__item') || ev.target.classList.contains('main-nav__link')) {
        // Do nothing
        this.previous = null
      } else {
        this.closeMenus();
      }
    })
  }

  closeMenus() {
    let headerItem = this.menu.querySelector('.main-nav__item.open')
    let footerItem = document.querySelector('.main-nav--footer .main-nav__item.open')
    if (headerItem) {
      headerItem.querySelector('.main-nav__list--sublist').removeAttribute('style')
      headerItem.classList.remove('open')
      this.header.classList.remove('fill')
      this.header.removeAttribute('style')
    }
    if (footerItem) {
      footerItem.querySelector('.main-nav__list--sublist').removeAttribute('style')
      footerItem.classList.remove('open')
    }
  }

}

export default MobileMenu