import Swiper from "swiper"
import { Pagination, Autoplay } from 'swiper/modules'
import PartnerSlider from "./modules/PartnerSlider"
import Lightbox from "./modules/Lightbox"
import ExploreButton from "./modules/ExploreButton"
import MobileMenu from "./modules/MobileMenu"
import ProgressCircles from "./modules/ProgressCircles"
import StickyNav from "./modules/StickyNav"
import NewsletterHandler from "./modules/NewsletterHandler"

document.addEventListener('DOMContentLoaded', () => {
  const mobileMenu = new MobileMenu()
  const partners = document.querySelectorAll('.partners')
  if (partners) {
    partners.forEach(partner => new PartnerSlider(partner))
  }

  const newsletterForms = document.querySelectorAll('.newsletter-signup')
  if (newsletterForms) {
    newsletterForms.forEach(form => new NewsletterHandler(form))
  }

  const lightboxes = document.querySelectorAll('.lightbox')
  if (lightboxes) {
    lightboxes.forEach(lightbox => new Lightbox(lightbox))
  }

  const heroSlider = document.querySelector('.hero-front .swiper')
  if (heroSlider) {
    const heroSwiper = new Swiper(heroSlider, {
      modules: [Pagination, Autoplay],
      speed: 1000,
      autoplay: {
        delay: 10000,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

  const exploreButton = document.querySelector('.hero-front__scrollpast-button')
  if (exploreButton) {
    const explore = new ExploreButton(exploreButton)
  }

  const numbers = document.querySelector('.numbers')
  if (numbers) {
    const progressCircles = new ProgressCircles()
  }

  const stickyNav = new StickyNav()
})