class ProgressCircle {

  constructor() {
    this.listen()
  }

  listen() {
    // Sjekk når numbers kommer inn i viewport
    let numbers = document.querySelectorAll('.numbers__number');
    numbers.forEach(number => {
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio >= .25) {
              const progress = number.dataset.progress
              const circle = number.querySelector('.numbers__svg .bar')
              const radius = circle.getAttribute('r')
              const circumference = Math.PI*(radius*2)

              if (progress < 0) progress = 0
              if (progress > 100) progress = 100

              const percentage = ((100 - progress) / 100) * circumference
              circle.style.strokeDashoffset = percentage + "px"
              observer.unobserve(entry.target)
            }
          });
        },
        { threshold: .25 }
      );
      observer.observe(number);
    })


  }

}

export default ProgressCircle