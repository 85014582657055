class NewsletterHandler {

  constructor(element) {
    this.form = element.querySelector('form')
    this.listen()
  }

  listen() {
    this.form.addEventListener('submit', ev => {
      const div = this.form.querySelector('div')
      ev.preventDefault()
      const data = {
        method: 'POST',
        Headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: new FormData(this.form)
      }
      fetch(this.form.action, data).then(res => {
        if (res.status == 200) {
          div.innerHTML = '<p style="font-size: 2rem;">Thank you!</p>'
        } else {
          div.innerHTML = '<p style="font-size: 2rem;">Something went wrong - please try again later</p>'
        }
      })
    })
  }

}

export default NewsletterHandler