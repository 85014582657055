class PartnerSlider {

  constructor(module) {
    this.module = module
    this.fader = null
    this.cards
    this.init()
  }

  init() {
    const large = 1024
    let isLarge = false

    if (window.innerWidth > large) {
      this.desktopAnimation()
      isLarge = true
    } else {
      this.mobileAnimation()
    }
    addEventListener('resize', () => {
      if (!isLarge && window.innerWidth > large) {
        isLarge = !isLarge
        this.desktopAnimation()
      }
      if (isLarge && window.innerWidth < large) {
        isLarge = !isLarge
        this.mobileAnimation()
      }
    })
  }

  desktopAnimation() {
    this.cards = this.module.querySelectorAll('.partners__list .partners__logo')
    const images = this.module.querySelectorAll('.partners__list-holder .partners__logo')

    if (this.cards.length > 3) {
      for (let i = 3; i < this.cards.length; i++) {
        this.cards[i].parentElement.remove()
      }
    }

    this.cards[0].src = images[0].src
    this.cards[1].src = images[1].src
    this.cards[2].src = images[2].src

    let currentIndex = 3

    let i = 0
    this.fader = setInterval(() => {
      if (i > this.cards.length - 1) i = 0
      this.cards.forEach(card => {
        card.style.opacity = 1
        if (images[currentIndex]) {
          card.src = images[currentIndex].src
          i++
          if (currentIndex == images.length - 1) {
            currentIndex = 0
          } else {
            currentIndex++
          }
        }
        setTimeout(() => {
          card.style.opacity = 0
        }, 2000)
      })
    }, 2500)

  }

  mobileAnimation() {
    const list = this.module.querySelector('.partners__list')
    this.cards = list.querySelectorAll('.partners__logo')
    const images = this.module.querySelectorAll('.partners__list-holder .partners__logo')

    clearInterval(this.fader)
    let timeout = 0
    let removeOpacity = setInterval(() => {
      this.cards.forEach(card => card.removeAttribute('style'))
      timeout++
      if (timeout === 10) {
        clearInterval(removeOpacity)
      }
    }, 250)

    this.cards[1].src = images[1].src
    this.cards[2].src = images[2].src
    if (images.length > 3) {
      for (let i = 0; i < images.length; i++) {
        if (i < 3) {
          this.cards[i].src = images[i].src
        } else {
          const template = document.querySelector('#partners-logo')
          const card = template.content.cloneNode(true)
          card.querySelector('.partners__logo').src = images[i].src
          list.appendChild(card)
        }
      }
      Array.from(list.children).forEach(item => {
        const dupe = item.cloneNode(true)
        dupe.setAttribute('aria-hidden', true)
        list.appendChild(dupe)
      })
    }
  }


}

export default PartnerSlider