class ExploreButton {

  constructor(button) {
    this.listen(button)
  }

  listen(button) {
    button.addEventListener('click', () => {
      button.closest('.hero-front').nextElementSibling.scrollIntoView()
    })
  }

}

export default ExploreButton