class StickyNav {

  constructor() {
    this.header = document.querySelector('.header')
    this.listen()
  }

  listen() {
    let turned_on = false
    window.addEventListener('scroll', () => {
      if (!turned_on && window.scrollY > 400) {
        this.header.classList.add('header--sticky')
        turned_on = true
      }
      if (turned_on && window.scrollY < 5) {
        this.header.classList.remove('header--sticky')
        turned_on = false
      }
    })
  }

}

export default StickyNav